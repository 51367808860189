import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { skuToUrl } from '../utils/sku-to-url'
import { productSku } from '../product-sku'
import SvgThinRightArrow from '../generated/svg/thin-right-arrow'
import classNames from 'classnames'
import { AssetImage } from './image'

const Outer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: var(--header-height);
  top: 100%;
  background-color: white;
  padding: 3.75rem 5.625rem;
  width: max-content;
  height: max-content;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s, transform 0.4s;
  transform: translateY(-1rem);
  @media (max-width: 40rem){
    position: static;
    top: auto;
    left: auto;
    right: auto;
    opacity: 1;
    transform: none;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2.5rem;
    padding-bottom: 0.25rem;
    background-color: transparent;
    margin: 0;
    width: 100%;
  }
  :has(>&):hover & {
    opacity: 1;
    pointer-events: initial;
    transform: none;
  }
`

const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
  @media (max-width: 40rem){
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`

const Item = styled.div`
  display: block;
  @media (max-width: 40rem){
    margin-bottom: -0.25rem; 
  }
  & + & {
    @media (max-width: 40rem){
      padding-top: 2.5rem;
      margin-top: 2rem;
      border-top: 1px solid #3F3939;
    }
  }

`

const NameContainer = styled.a`
  display: block;
  transition: opacity 0.4s;
  width: fit-content;
  a&:hover{
    opacity: 0.5;
  }
  a${Item}:hover & {
    opacity: 0.5;
  }
`

const NameEn = styled.div`
  font-family: var(--en-font);
  font-size: 0.9375rem;
  color: var(--color);
  font-weight: 700;
  @media (max-width: 40rem){
    font-size: 1.125rem;
  }
`

const NameJa = styled.div`
  font-size: 0.75rem;
  margin-top: 0.5rem;
  color: var(--color);
`

const List = styled.div`
  margin-top: 1rem;
`

const ListItem = styled.a`
  display: block;
  line-height: 2.5;
  font-size: 0.875rem;
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.5;
  }
  &::before {
    content: '-';
    margin-right: 0.25em;
  }
`

const Arrow = styled.div`
  margin-top: 1rem;
  transition: opacity 0.4s;
  ${Item}:hover & {
    opacity: 0.5;
  }
  svg {
    display: block;
    height: 0.5rem;
    width: auto;
    transition: transform 0.4s;
    ${Item}:hover & {
      transform: translateX(0.5rem);
    }
  }
`

const Img = styled.div`
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`

const Recommend = styled.div`
  font-weight: 700;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--color);
  &::before {
    content: '╲';
    display: block;
  }
  &::after {
    content: '╱';
    display: block;
  }
`


type HeaderPopMenuProps = {
}
export const HeaderPopMenu: FunctionComponent<HeaderPopMenuProps> = () => {
  return <Outer>
    <Inner>
      <Item style={{'--color': '#5395A1'}}>
        <NameContainer href={skuToUrl(productSku.peelCare10.single)}>
          <NameEn>
            PeelCare Serum
          </NameEn>
          <NameJa>
            角質ケア
          </NameJa>
        </NameContainer>
        <List>
          <ListItem href={skuToUrl(productSku.peelCare10.single)}>ピールケアセラム1.0</ListItem>
          <ListItem href={skuToUrl(productSku.peelCare20.single)}>ピールケアセラム2.0</ListItem>
          <ListItem href={skuToUrl(productSku.peelCare30.single)}>ピールケアセラム3.0</ListItem>
        </List>
      </Item>
      <Item style={{'--color': '#E98760'}}>
        <NameContainer href={skuToUrl(productSku.retinoDermo01.single)}>
          <NameEn>
            RetinoDerm™ Cream
          </NameEn>
          <NameJa>
            集中ケア
          </NameJa>
        </NameContainer>
        <List>
          <ListItem href={skuToUrl(productSku.retinoDermo01.single)}>レチノダーマクリーム0.1</ListItem>
          <ListItem href={skuToUrl(productSku.retinoDermo02.single)}>レチノダーマクリーム0.2</ListItem>
          <ListItem href={skuToUrl(productSku.retinoDermo04.single)}>レチノダーマクリーム0.4</ListItem>
        </List>
      </Item>
      <Item style={{'--color': '#A98EBB'}}>
        <NameContainer href={skuToUrl(productSku.hydroLight25.single)}>
          <NameEn>
            HydroLight™ Cream
          </NameEn>
          <NameJa>
            集中ケア
          </NameJa>
        </NameContainer>
        <List>
          <ListItem href={skuToUrl(productSku.hydroLight25.single)}>ハイドロライトクリーム2.5</ListItem>
          <ListItem href={skuToUrl(productSku.hydroLight40.single)}>ハイドロライトクリーム4.0</ListItem>
        </List>
      </Item>
      <Item style={{'--color': '#D9AFCB'}}>
        <NameContainer href={skuToUrl(productSku.niacinaGlow.single)}>
          <NameEn>
            NiacinaGlow™ Gel
          </NameEn>
          <NameJa>
            保湿ケア
          </NameJa>
        </NameContainer>
        <List>
          <ListItem href={skuToUrl(productSku.niacinaGlow.single)}>ナイアシナグロージェル</ListItem>
        </List>
      </Item>
      <Item style={{'--color': '#B2A075'}}>
        <NameContainer href={skuToUrl(productSku.skinCycleMethod3PieceSet.single)}>
          <Recommend>おすすめ</Recommend>
          <NameEn>
            THREE-PIECE SET
          </NameEn>
          <NameJa>
            3点セット
          </NameJa>
        </NameContainer>
        <List>
          <ListItem href={skuToUrl(productSku.skinCycleMethod3PieceSet.single)}>グレード1.0セット</ListItem>
        </List>
      </Item>
      {/* <Item style={{'--color': '#B2A075'}} as="a" href={skuToUrl(productSku.freeSelect.single)}>
        <NameContainer as="div">
          <NameEn>
            FREE SELECT SET
          </NameEn>
          <NameJa>
            フリーセレクトセット
          </NameJa>
        </NameContainer>
        <Arrow>
          <SvgThinRightArrow />
        </Arrow>
      </Item> */}
    </Inner>
  </Outer>
}