import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'
import SvgLogo from '../generated/svg/logo'
import SvgCartIcon from '../generated/svg/cart-icon'
import SvgUserIcon from '../generated/svg/user-icon'
import { Menu } from './menu'
import { HeaderPopMenu } from './header-pop-menu'

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.4s, transform 0.4s;
  &.hide {
    opacity: 0;
    transform: translateY(-100%);
  }
`

const Inner = styled.div`
  padding: 1.6875rem 1.6875rem 1rem;
  display: flex;
  align-items: stretch;
  @media (max-width: 40rem){
    padding: 1rem 1rem;
  }
`

const LogoContainer = styled.a`
  display: block;
  transition: opacity 0.4s;
  &:hover {
    opacity: 0.5;
  }
  svg {
    display: block;
    height: 3.125rem;
    width: auto;
    @media (max-width: 40rem){
      height: 3.5rem;
    }
  }
`

const Navigator = styled.div`
  display: flex;
  align-items: stretch;
  margin-left: 6rem;
  gap: 1rem;
  @media (max-width: 40rem){
    display: none;
  }
`

const NavigatorItem = styled.a`
  display: block;
  font-size: 0.875rem;
  letter-spacing: 0.08em;
  font-family: var(--en-font);
  display: flex;
  align-items: center;
  padding: 2rem 1rem;
  margin: -1rem 0;
  @media (min-width: 80rem){
    position: relative;
  }
`
const NavigatorItemlabel = styled.a`
  height: 100%;
  transition: opacity 0.4s;
  display: block;
  ${NavigatorItem}:hover & {
    opacity: 0.5;
  }
`

const RightContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: stretch;
  margin-right: -0.5rem;
`

const IconLink = styled.a`
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  transition: opacity 0.4s;
  z-index: 1000;
  &:hover {
    opacity: 0.5;
  }
  svg {
    display: block;
    height: 1.875rem;
    width: auto;
    @media (max-width: 40rem){
      height: 2rem;
    }
  }
`

const MenuIcon = styled.div`
  display: none;
  @media (max-width: 40rem){
    display: block;
    position: relative;
    width: 1.75rem;
    padding: 0 0.5rem;
    cursor: pointer;
    z-index: 1000;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 1.75rem;
      height: 1px;
      top: 50%;
      left: 0.5rem;
      background-color: currentColor;
      transition: transform 0.4s;
    }
    &::before {
      transform: translateY(calc(-50% - 0.1875rem));
    }
    &::after {
      transform: translateY(calc(-50% + 0.1875rem));
    }
    &.open::before {
      transform: translateY(calc(-50%)) rotate(45deg);
    }
    &.open::after {
      transform: translateY(calc(-50%)) rotate(-45deg);
    }
  }
`

const Banner = styled.a`
  display: block;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.75rem;
  text-align: center;
  background-color: #d2686f;
  color: white;
  transition: margin-top 0.4s;
  &.hide {
    margin-top: -2.5rem;
  }
`


type HeaderProps = {

}
export const Header: FunctionComponent<HeaderProps> = () => {
  const [hide, setHide] = useState(false)
  const [open, setOpen] = useState(false)
  const [isTop, setIsTop] = useState(false)
  const outerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    let lastScrollY = window.scrollY
    const listener = () => {
      const scrollY = window.scrollY
      setIsTop(!(scrollY > 0))
      if(scrollY === 0 || scrollY < lastScrollY){
        setHide(false)
      } else {
        setHide(true)
      }
      lastScrollY = scrollY
    }
    listener()
    window.addEventListener('scroll', listener)
    return () => {
      window.removeEventListener('scroll', listener)
    }
  }, [])
  useEffect(() => {
    const outer = outerRef.current
    if(outer){
      const listener = () => {
        const {height} = outer.getBoundingClientRect()
        document.body.style.setProperty('--header-height', `${height}px`)
      }
      const observer = new ResizeObserver(listener)
      observer.observe(outer)
      listener()
      return () => {
        observer.disconnect()
      }
    }
  }, [])
  return <>
    <Outer ref={outerRef} className={classNames({hide: !open && hide})}>
      <Inner>
        <LogoContainer href="/shop">
          <SvgLogo />
        </LogoContainer>
        <Navigator>
          <NavigatorItem>
            <NavigatorItemlabel href="/shop/information_categories/topics">
              TOPICS
            </NavigatorItemlabel>
          </NavigatorItem>
          <NavigatorItem>
            <NavigatorItemlabel href="/shop/products">
              PRODUCTS
            </NavigatorItemlabel>
            <HeaderPopMenu/>
          </NavigatorItem>
          <NavigatorItem>
            <NavigatorItemlabel href="/shop/faq">
              FAQ
            </NavigatorItemlabel>
          </NavigatorItem>
          <NavigatorItem>
            <NavigatorItemlabel href="/shop/information_categories/news">
              NEWS
            </NavigatorItemlabel>
          </NavigatorItem>
        </Navigator>
        <RightContainer>
          <IconLink href="/shop/cart">
            <SvgCartIcon />
          </IconLink>
          <IconLink href="/shop/customer">
            <SvgUserIcon />
          </IconLink>
          <MenuIcon className={classNames({open})} onClick={() => setOpen(v => !v)} />
          <Menu open={open} />
        </RightContainer>
      </Inner>
    </Outer>
  </>
}